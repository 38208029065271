import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import Downshift, { DownshiftState, StateChangeOptions } from 'downshift'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, MenuItem, Theme } from '@material-ui/core'
import { useAutoCompleteCertificateLazyQuery } from '@src/queries/AutoCompleteCertificateQuery.generated'
import { CertificateSearchType } from '@utils/searchUtils'
import LiquidInput, {
  LiquidInputProps,
} from '@src/components/LiquidInput/LiquidInput'

const useStyles = makeStyles((theme: Theme) => {
  return {
    autoSuggestContainer: { position: 'relative' },
    autoSuggest: {
      left: 0,
      right: 0,
      zIndex: 1,
      position: 'absolute',
      marginTop: theme.spacing(2),
      maxHeight: 268,
      overflowY: 'auto',
    },
    autoSuggestItem: {
      fontSize: theme.typography.pxToRem(14),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  }
})
interface CertificateAutoCompleteProps
  extends Omit<LiquidInputProps, 'onChange' | 'value'> {
  value: string
  onChange: (item: any) => void
  rootClass?: string
  rootStyle?: React.CSSProperties
  error?: boolean
  type: CertificateSearchType
  brand?: string
  lotNumber?: string
  productNumber?: string
}
const stateReducer = (
  state: DownshiftState<any>,
  changes: StateChangeOptions<any>
) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.mouseUp:
    case Downshift.stateChangeTypes.blurInput:
      return {
        ...changes,
        inputValue: state.inputValue,
      }
    default:
      return changes
  }
}
const LiquidCertficateAutoComplete: React.FC<CertificateAutoCompleteProps> = ({
  value,
  onChange,
  rootClass,
  rootStyle,
  error,
  type,
  brand,
  productNumber,
  lotNumber,
  ...rest
}) => {
  const classes = useStyles()
  const searchVariables = {
    brand,
    lotNumber,
    productNumber,
    term: value,
    type,
  }
  const [getAutoCompleteCertificate, searchSuggestionsResponse] =
    useAutoCompleteCertificateLazyQuery()

  useEffect(() => {
    // Prevent spaces and typeless searches (SDS part numbers) from triggering autocomplete.
    if (searchVariables.term.trim().length > 0 && type !== undefined) {
      getAutoCompleteCertificate({
        variables: searchVariables,
      })
    }
    // NOTE: adding combinedSearchVariables causes a hang in ProductDetails tests
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, getAutoCompleteCertificate])

  const { data } = searchSuggestionsResponse

  const [userInput, setUserInput] = useState('')

  return (
    <Downshift
      id={`autocomplete-${type}`} // Use a dynamic or unique ID
      inputValue={value}
      onInputValueChange={(inputValue) => {
        // called when user types in input field
        setUserInput(inputValue)
        return onChange(inputValue)
      }}
      itemToString={() => (userInput ? userInput : value)}
      stateReducer={stateReducer}
      onChange={(dropdownSelection) => {
        // called when user selects from dropdown
        setUserInput(dropdownSelection.value)
        return onChange(dropdownSelection.value)
      }}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
      }) => {
        const { onChange, onKeyDown, ...inputProps } = getInputProps()
        return (
          <div
            className={clsx({ [String(rootClass)]: rootClass })}
            style={rootStyle}
          >
            <LiquidInput
              autoComplete="off"
              inputProps={{ ...inputProps }}
              name={`${type}-input`}
              onChange={
                onChange as
                  | React.ChangeEventHandler<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  | undefined
              }
              onKeyDown={onKeyDown}
              value={inputValue}
              error={error}
              {...rest}
            />
            {isOpen && data && data.getAutoCompleteCertificate && (
              <div {...getMenuProps()} className={classes.autoSuggestContainer}>
                <Paper square className={classes.autoSuggest}>
                  {data &&
                    inputValue &&
                    inputValue.length >= 3 &&
                    data.getAutoCompleteCertificate.results &&
                    data.getAutoCompleteCertificate.results.map(
                      (item, index) => (
                        <MenuItem
                          key={item.value}
                          {...getItemProps({
                            key: item.value,
                            index,
                            item: item,
                          })}
                          component="div"
                          selected={index === highlightedIndex}
                          className={classes.autoSuggestItem}
                        >
                          {item.value}
                        </MenuItem>
                      )
                    )}
                </Paper>
              </div>
            )}
          </div>
        )
      }}
    </Downshift>
  )
}

export default LiquidCertficateAutoComplete
